/* global google */

import Rails from 'rails-ujs';
import GoogleMap from '../classes/google_map';
import EstateButton from '../classes/estate_button';
import EstateMarker from '../classes/estate_marker';
import EstateInfoWindow from '../classes/estate_info_window';
import { Controller } from 'stimulus';
import Suggestrap from 'suggestrap'

export default class extends Controller {
  static targets = [ 'map', 'lat', 'lng', 'list', 'estate', 'pagination', 'paginationTop', 'totalCount', 'zoom', 'order', 'prefecture', 'city', 'minPrice', 'maxPrice', 'room', 'minFootprint', 'maxFootprint', 'currentState', 'walkingMinute', 'feature', 'keyword', 'form', 'mainContent', 'mobileMainContent', 'perPage', 'line', 'station', 'output', 'area' ]

  initialize() {
    this.set_events();
    GoogleMap.dispatchCallbackEvent();
  }

  connect() {
    this.updatingEstates = false;
    this.ajaxEstate = null;

    addEventListener('DOMContentLoaded', () => {
      this.adjustMapHeight();
      this.disableSelectOptions(this.minPriceTarget, this.maxPriceTarget);
      this.disableSelectOptions(this.minFootprintTarget, this.maxFootprintTarget);
    });

    addEventListener('resize', () => {
      this.adjustMapHeight();
    });

    if (this.hasLineTarget) {
      new Suggestrap({
        target: this.lineTarget.id,
        values: JSON.parse(this.lineTarget.dataset.lines).map((line) => {
          return { name: line }
        }),
        key: 'name'
      }, {
        minlength: 1,
        delay: 0,
        count: 100
      })
      $(this.lineTarget).on('blur', (event) => {
        // 選択した候補がすぐに反映されないため、少し待つ
        setTimeout(() => {
          this.formChanged(event)
        }, 100);
      })
    }
    if (!this.displayKeyword) {
      this.outputTarget.textContent = '指定なし'
      this.searchTexts = [...Array(22)]
    } else {
      this.searchTexts = this.displayKeyword.split('.')
      this.changeConditionText()
    }
  }

  /*
   * イベント設定
   */
  set_events() {
    let searchBody = $('.search-wrap__body');
    let tabHeight = 0;
    let fixedElement = $('.search-wrap__tab');
    let searchHead = $('.search-wrap__head');
    let searchBodyDisplay = $('.search-wrap__body__display');
    let btnDisplayListSp = $('.btn-display-list__sp');
    let btnDisplayMapSp = $('.btn-display-map__sp');

    btnDisplayListSp.addClass('active');

    $(window).on('load resize', function() {
      if( $('.search-wrap__tab').is(':visible') ) {
        tabHeight = $('.search-wrap__tab').outerHeight();
      } else {
        tabHeight = 0;
      }

      if( $(window).width() > 991 ) {
        $('#tab2.search-wrap__body__map').show();
        searchBody.outerHeight( $(window).height() - ($('#header').outerHeight() + tabHeight + $('.search-wrap__head').outerHeight()) );
      }
    });

    $(window).scroll(function() {
      let scrollTop = $(this).scrollTop();

      if( scrollTop > $('.search-wrap__head').offset().top + $('.search-wrap__head').outerHeight() ) {
        fixedElement.addClass('fixed');
      } else {
        fixedElement.removeClass('fixed');
      }
    });

    $('#estateSearchList').on('click', function(e) {
      if( $(window).width() <= 991 ) {
        searchBody.css('height', 'auto');;
        searchHead.css('display','block');
        searchBodyDisplay.css('display','flex');
        btnDisplayListSp.addClass('active');
        btnDisplayMapSp.removeClass('active');
      }
    });

    $('#estateSearchMap').on('click', function(e) {
      if( $(window).width() <= 991 ) {
        searchBody.outerHeight( $(window).height() - ($('#header').outerHeight() + tabHeight) );
        searchHead.css('display','none');
        searchBodyDisplay.css('display','none');
        btnDisplayListSp.removeClass('active');
        btnDisplayMapSp.addClass('active');
      }
    });
  }

  initializeMap() {
    this.map = new google.maps.Map(this.mapTarget, this.mapOptions());
    this.getEstates();
    this.map.addListener('click', this.closeInfoWindowIfMapTapped)

    EstateInfoWindow.prototype = Object.create(google.maps.OverlayView.prototype);
    this.infoWindow = new EstateInfoWindow();
    this.infoWindow.setMap(this.map);
    google.maps.event.addDomListener(this.infoWindow.content, 'click', () => {
      this.infoWindow.openEstate();
    });

    EstateMarker.prototype = Object.create(google.maps.OverlayView.prototype);
    this.markers = [];
    if (this.isArchive == 'false') {
      this.createMarkers();
    }

    if (this.isAreaSearch  || this.isAreaClassificationSearch) {
      this.reSearchButton = new EstateButton({ text: 'エリア内を再検索する' });
      this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(this.reSearchButton.tag);
      this.reSearchButton.tag.addEventListener('click', () => this.getEstatesByLocation());
      this.map.addListener('dragend', () => this.dragend());
      this.map.addListener('zoom_changed', () => this.zoomChanged());
    }
    google.maps.event.trigger(this.map, 'resize');
    this.map.setCenter({ lat: Number(this.latTarget.value), lng: Number(this.lngTarget.value) });
  }

  mapOptions() {
    const options = GoogleMap.options();
    options.center = { lat: Number(this.latTarget.value), lng: Number(this.lngTarget.value) };
    options.zoom = Number(this.zoomTarget.value);
    options.gestureHandling = 'greedy'
    return options;
  }

  adjustMapHeight() {
    const height = window.innerHeight - ($(window).width() <= 991 ? this.mobileMainContentTarget.offsetTop : this.mainContentTarget.offsetTop)
    this.mapTarget.style.height = `${height}px`;
  }

  createMarkers() {
    this.estateTargets.forEach(estate => {
      this.markers.push(
        this.createMarker({
          estateId: estate.dataset.id,
          lat: Number(estate.dataset.lat),
          lng: Number(estate.dataset.lng)
        })
      );
    });
  }

  dragend() {
    this.reSearchButton.show();
  }

  zoomChanged() {
    this.reSearchButton.show();
    this.zoomTarget.value = this.map.getZoom();
  }

  createMarker({ estateId, lat, lng }) {
    const position = new google.maps.LatLng(lat, lng);
    const marker = new EstateMarker(estateId, position);

    google.maps.event.addDomListener(marker.content, 'click', () => {
      this.openInfoWindowOrEstate(marker);
    });

    google.maps.event.addDomListener(this.map, 'click', () => {
      this.closeInfoWindow(marker);
    });

    if (!this.isSmartphone) {
      google.maps.event.addDomListener(marker.content, 'mouseover', () => {
        this.openInfoWindowOrEstate(marker);
      });

      google.maps.event.addDomListener(marker.content, 'mouseout', () => {
        this.closeInfoWindow(marker);
      });
    }

    marker.setMap(this.map);
    return marker;
  }

  openInfoWindowOrEstate(marker) {
    if (marker.isFocused()) {
      this.infoWindow.openEstate();
    } else {
      const estate = this.estateTargets.find(estate => {
        return marker.estateId === estate.dataset.id;
      });

      if (!estate)
        return;

      marker.prepareForOpeningInfoWindow();
      this.infoWindow.setContent(this.infoWindowContent(estate));
      this.infoWindow.open(marker.estateId, marker.position);
    }
  }

  infoWindowContent(estate) {
    if (this.isArchive == 'true') {
      return {
        image: estate.querySelector('.recently__img img.map_image').src,
        name: estate.querySelector('.recently__ttl').textContent,
        address: estate.querySelector('.recently__txt').dataset.mapShowAddress,
        station: estate.querySelector('.recently__station').dataset.mapShowStation
      };
    } else {
      return {
        image: estate.querySelector('.recently__img img.map_image').src,
        price: estate.querySelector('.recently__price').textContent,
        name: estate.querySelector('.recently__ttl').textContent,
        roomplan: estate.querySelector('.recently__desc').textContent,
        address: estate.querySelector('.recently__txt').dataset.mapShowAddress,
        station: estate.querySelector('.recently__station').dataset.mapShowStation
      };
    }
  }

  closeInfoWindow(marker) {
    if (!marker)
      return;

    marker.prepareForClosingInfoWindow();
    this.infoWindow.close();
  }

  openInfoWindowTrigger(event) {
    if (!this.markers)
      return;

    this.focusedMarker = this.markers.find(marker => {
      return marker.estateId === event.currentTarget.dataset.id;
    });

    if (this.focusedMarker) {
        setTimeout(() => {
          if (this.focusedMarker && this.focusedMarker.hasOwnProperty('position') && this.focusedMarker.position !== null) {
            this.map.panTo(this.focusedMarker.position);
          }
        }, 500);
      google.maps.event.trigger(this.focusedMarker.content, event.type);
    }
  }

  closeInfoWindowTrigger(event) {
    if (this.focusedMarker) {
      google.maps.event.trigger(this.focusedMarker.content, event.type);
      this.focusedMarker = null;
    }
  }

  closeInfoWindowIfMapTapped() {
    if (!this.isSmartphone || this.isMapObjectTapped(event))
      return;

    const marker = this.markers.find(marker => {
      return marker.isFocused();
    });

    this.closeInfoWindow(marker);
  }

  isMapObjectTapped(event) {
    const isMarkerTapped = event.target.classList.contains('estate-map-marker');
    const isInfoWindowTapped = Array.from(event.target.parentNode.classList).join().indexOf('estate-map-info-window-content') >= 0;
    return isMarkerTapped || isInfoWindowTapped;
  }

  disableSelectOptions(minTarget, maxTarget) {
    const minValue = Number(minTarget.value);

    Array.from(maxTarget.options).forEach(option => {
      const maxValue = Number(option.value);

      if (maxValue === 0)
        return;

      if (minValue >= maxValue) {
        option.disabled = true;
        if (option.selected)
          maxTarget.options[0].selected = true;
      } else {
        option.disabled = false;
      }
    });
  }

  enableLocation() {
    const center = this.map.getCenter();
    this.latTarget.value = center.lat();
    this.lngTarget.value = center.lng();
    this.latTarget.disabled = false;
    this.lngTarget.disabled = false;
    this.SouthWestLat = this.map.getBounds().getSouthWest().lat()
    this.SouthWestLng = this.map.getBounds().getSouthWest().lng()
    this.NorthEastLat = this.map.getBounds().getNorthEast().lat()
    this.NorthEastLng = this.map.getBounds().getNorthEast().lng()
    this.reSearchButton.hide();
  }

  disableLocation() {
    this.latTarget.disabled = true;
    this.lngTarget.disabled = true;
    this.reSearchButton.hide();
  }

  formChangedWithAreaCheckAll(event) {
    this.cityTargets.forEach(element => {
      if (event.currentTarget.dataset.estateSearchGroupArea === element.dataset.estateSearchGroupArea) {
        element.checked = event.target.checked;
      }
    });
    this.formChanged(event);
  }

  changeConditionText() {
    if (this.searchTexts.filter(Boolean).length) {
      setTimeout(() => {
        this.outputTarget.className = 'condition-txt'
        const uniqueSearchTexts = [...new Set(this.searchTexts)];
        this.outputTarget.textContent = uniqueSearchTexts.filter(Boolean).join(' | ')
      }, 1000)
    } else {
      setTimeout(() => {
        this.outputTarget.className = 'condition-txt'
        this.outputTarget.textContent = '指定なし'
      }, 1000)
    }
  }

  conditionChanged(target) {
    if(target.name == 'prefecture_id' || target.name == 'city_ids[]' || target.name == 'select_order') {
      return
    }

    this.outputTarget.className = 'loader'
    switch (true) {
      case target.name == 'min_price' || target.name == 'max_price':
        if (this.minPriceTarget.selectedIndex == 0 && this.maxPriceTarget.selectedIndex == 0) {
          this.searchTexts.splice(0, 1, null)
          this.changeConditionText()
          break
        }
        this.searchTexts.splice(0, 1, `${this.minPriceTarget.options[`${this.minPriceTarget.selectedIndex}`].text} 〜 ${this.maxPriceTarget.options[`${this.maxPriceTarget.selectedIndex}`].text}`)
        this.changeConditionText()
        break

      case target.name == 'min_footprint' || target.name == 'max_footprint':
        if (this.minFootprintTarget.selectedIndex == 0 && this.maxFootprintTarget.selectedIndex == 0) {
          this.searchTexts.splice(1, 1, null)
          this.changeConditionText()
          break
        }
        this.searchTexts.splice(1, 1, `${this.minFootprintTarget.options[`${this.minFootprintTarget.selectedIndex}`].text} 〜 ${this.maxFootprintTarget.options[`${this.maxFootprintTarget.selectedIndex}`].text}`)
        this.changeConditionText()
        break

      case target.name == 'rooms[]':
        if (target.checked) {
          this.searchTexts.slice(2, 8).some((elem, index) => {
            if (!elem) {
              this.searchTexts.splice(index + 2, 1, target.nextElementSibling.textContent)
              this.changeConditionText()
              return true
            }
          })
          break
        } else {
          this.searchTexts.slice(2, 8).some((elem, index) => {
            if (elem == target.nextElementSibling.textContent) {
              this.searchTexts.splice(index + 2, 1, null)
              this.changeConditionText()
              return true
            }
          })
          break
        }

      case target.name == 'walking_minute':
        if (this.walkingMinuteTarget.selectedIndex == 0) {
          this.searchTexts.splice(8, 1, null)
          this.changeConditionText()
          break
        }
        this.searchTexts.splice(8, 1, `${this.walkingMinuteTarget.options[`${this.walkingMinuteTarget.selectedIndex}`].text}`)
        this.changeConditionText()
        break

      case target.name == 'current_states[]':
        if (target.checked) {
          this.searchTexts.slice(9, 11).some((elem, index) => {
            if (!elem) {
              this.searchTexts.splice(index + 9, 1, target.nextElementSibling.textContent)
              this.changeConditionText()
              return true
            }
          })
          break
        } else {
          this.searchTexts.slice(9, 11).some((elem, index) => {
            if (elem == target.nextElementSibling.textContent) {
              this.searchTexts.splice(index + 9, 1, null)
              this.changeConditionText()
              return true
            }
          })
          break
        }

      case target.name == 'features[]':
        if (target.checked) {
          this.searchTexts.slice(11, 22).some((elem, index) => {
            if (!elem) {
              this.searchTexts.splice(index + 11, 1, target.nextElementSibling.textContent)
              this.changeConditionText()
              return true
            }
          })
          break
        } else {
          this.searchTexts.slice(11, 22).some((elem, index) => {
            if (elem == target.nextElementSibling.textContent) {
              this.searchTexts.splice(index + 11, 1, null)
              this.changeConditionText()
              return true
            }
          })
          break
        }
    }
  }

  hashTagConditionChanged(target) {
    const obsessedConditions = {
      'allow_pet': 'ペット可',
      'renovation': 'リノベーション',
      'r1': 'R1住宅適合物件',
      'auto_lock': 'オートロック',
      'smaview': 'Smaview対応',
      'energy_saving': '省エネ住宅',
      'r1_eco': 'R1エコ',
      'tax_reduction': '省エネ基準減税',
    };
    const obsessedConditionKeys = Object.keys(obsessedConditions);
    if (target === 'invest') {
      this.outputTarget.className = 'loader';
      if (this.searchTexts.includes('投資用のみ')) {
        this.outputTarget.className = 'condition-txt';
        return;
      }
      for (let i = 9; i <= 11; i++) {
        if (!this.searchTexts[i]) {
          this.searchTexts.splice(i, 1, '投資用のみ');
          this.changeConditionText();
          break;
        }
      }
    } else if (obsessedConditionKeys.includes(target)) {
      this.outputTarget.className = 'loader';
      const text = obsessedConditions[target];
      if (this.searchTexts.includes(text)) {
        this.outputTarget.className = 'condition-txt';
        return;
      }
      for (let i = 11; i <= 22; i++) {
        if (!this.searchTexts[i]) {
          this.searchTexts.splice(i, 1, text);
          this.changeConditionText();
          break;
        }
      }
    }
  }

  formChanged(event) {
    const target = event.currentTarget;

    switch (target.name) {
    case 'min_price':
      this.disableSelectOptions(this.minPriceTarget, this.maxPriceTarget);
      break;
    case 'min_footprint':
      this.disableSelectOptions(this.minFootprintTarget, this.maxFootprintTarget);
      break;
    case 'select_order':
      this.orderTarget.value = target[target.selectedIndex].value;
      break;
    case 'prefecture_id':
      if (this.hasCityTarget) {
        this.disableLocation();
        this.cityTarget.innerHTML = '';
      }
      this.SouthWestLat = null
      this.SouthWestLng = null
      this.NorthEastLat = null
      this.NorthEastLng = null
      break;
    case 'city_ids[]':
      this.disableLocation();
      this.SouthWestLat = null
      this.SouthWestLng = null
      this.NorthEastLat = null
      this.NorthEastLng = null
      break;
    case 'line_name':
      this.stationTarget.value = null;
      break;
    }

    this.setMoveCenterAfterRendering(target.name);

    if (this.pageNumber >= 2) {
      this.conditionChanged(target)
      const uniqueSearchTexts = [...new Set(this.searchTexts)]
      location.href = this.estatesUrl().replace(`&page=${this.pageNumber}`, '') + `display_keyword=${uniqueSearchTexts.join('.')}`

      return
    }
    this.getEstates();
    this.conditionChanged(target)
  }

  setMoveCenterAfterRendering(name) {
    this.moveCenterAfterRendering = ['prefecture_id', 'city_ids[]'].includes(name);
  }

  setCenter(position) {
    this.map.setCenter({ lat: position.lat, lng: position.lng });
  }

  getEstatesByLocation() {
    this.enableLocation();
    this.getEstates();
  }

  getEstates() {
    if (this.updatingEstates)
      return;

    this.updatingEstates = true;
    this.totalCountTarget.innerHTML = '<i class=\'fa fa-spinner fa-spin\'></i>&nbsp;&nbsp;読み込み中…';

    this.ajaxEstate = $.ajax({
      type: 'GET',
      dataType: 'json',
      url: this.estatesApiUrl,
      success: (json) => {
        this.renderEstates(json.estates, json.pagination);
        if (this.moveCenterAfterRendering)
          this.setCenter(json.position);
      },
      error: () => {},
      complete: () => { this.updatingEstates = false; }
    });
  }

  renderEstates(estates, pagination) {
    this.pageNumber = pagination.page
    const estatesCount = estates.length;
    const totalCount = pagination.total_count;
    this.removeMarkers();
    this.renderTotalCount(totalCount);
    this.renderPagination({
      estatesCount: estatesCount,
      totalCount: totalCount,
      perPage: pagination.per_page,
      pageNumber: pagination.page
    });

    if (estatesCount === 0)
      return;

    estates.forEach(estate => this.renderEstate(estate));
    if (this.isArchive == 'false') {
      this.createMarkers();
    }
  }

  renderTotalCount(totalCount) {
    this.totalCountTarget.innerHTML = `検索結果<span>${totalCount}</span>件`;
  }

  renderPagination({ estatesCount, totalCount, perPage, pageNumber }) {
    // totalCount: 検索結果の物件数
    // estatesCount: 検索結果からページに表示する物件数
    // perPage: ページに表示する最大件数(60件)
    // pageNumber: 表示しているページ

    this.paginationTopTarget.innerHTML = ''
    const topPagination = document.getElementById('is-top-pagination')
    if (estatesCount === 0) {
      this.paginationTarget.innerHTML = `
        <div class="pagination pagination-estates__empty">
          <div class="pagination-display-entries card-estates__empty">
            物件が見つかりませんでした。
          </div>
        </div>
      `;
      return;
    } else if (totalCount <= perPage) {
      this.paginationTarget.innerHTML = '';
      topPagination.classList.remove('card-estates__pagination', 'is-top')
      return;
    } else if (topPagination.classList.length == 0) {
      topPagination.classList.add('card-estates__pagination', 'is-top')
    }

    let estatesUrl = this.estatesUrl()
    estatesUrl = estatesUrl.replace(`&page=${this.pageNumber}`, '')
    estatesUrl = estatesUrl.replace(`zoom=${this.zoomTarget.value}&`, '')
    estatesUrl = estatesUrl + `display_keyword=${this.searchTexts.join('.')}&`
    const url = this.isArchive == 'true' ? this.archiveEstatesUrl() : estatesUrl

    const pageCount = Math.ceil(totalCount / perPage);
    let html = '';
    for (var page = 2; pageCount >= page; page++) {
      if (page === Number(pageNumber)){
        html += `
          <div class="is-page pagination__item is-current">
            <a class="pagination__link" href="${url}page=${page}">${page}</a>
          </div>`;
      } else {
        html += `
          <div class="is-page pagination__item">
            <a class="pagination__link" href="${url}page=${page}">${page}</a>
          </div>`;
      }
    }
    if (!pageNumber || pageNumber === "1"){
      this.paginationTarget.innerHTML = `
        <div class="pagination">
          <div class="pagination__items">
            <div class="is-blank"></div>
            <div class="is-page pagination__item is-current">1</div>
            ${html}
            <div class="is-next pagination__item">
              <a rel="next" class="pagination__link" href="${url}page=2">
                <i class="fa fa-angle-right"></i>
              </a>
            </div>
            <div class="is-last pagination__item">
              <a class="pagination__link" href="${url}page=${pageCount}">
                <i class="fa fa-angle-double-right"></i>
              </a>
            </div>
          </div>
          <div class="pagination-display-entries">
            1&nbsp;-&nbsp;${perPage}&nbsp;/&nbsp;${totalCount}
          </div>
        </div>`;
    } else if (perPage * Number(pageNumber) > totalCount) {
      this.paginationTarget.innerHTML = `
        <div class="pagination">
          <div class="pagination__items">
            <div class="is-first pagination__item">
              <a class="pagination__link" href="${url}"><i class="fas fa-angle-double-left"></i></a>
            </div>
            <div class="is-prev pagination__item">
              <a rel="prev" class="pagination__link" href="${url}page=${Number(pageNumber) - 1}"><i class="fas fa-angle-left"></i></a>
            </div>
            <div class="is-page pagination__item">
              <a class="pagination__link" href="${url}">1</a>
            </div>
            ${html}
          <div class="pagination-display-entries">
            ${perPage * (Number(pageNumber) - 1) + 1}&nbsp;-&nbsp;${totalCount}&nbsp;/&nbsp;${totalCount}
          </div>
        </div>`;
    }
    else {
      this.paginationTarget.innerHTML = `
        <div class="pagination">
          <div class="pagination__items">
            <div class="is-first pagination__item">
              <a class="pagination__link" href="${url}"><i class="fas fa-angle-double-left"></i></a>
            </div>
            <div class="is-prev pagination__item">
              <a rel="prev" class="pagination__link" href="${url}page=${Number(pageNumber) - 1}">
                <i class="fas fa-angle-left"></i>
              </a>
            </div>
            <div class="is-page pagination__item">
              <a class="pagination__link" href="${url}">1</a>
            </div>
            ${html}
            <div class="is-next pagination__item">
              <a rel="next" class="pagination__link" href="${url}page=${Number(pageNumber) + 1}">
                <i class="fa fa-angle-right"></i>
              </a>
            </div>
            <div class="is-last pagination__item">
              <a class="pagination__link" href="${url}page=${pageCount}">
                <i class="fa fa-angle-double-right"></i>
              </a>
            </div>
          </div>
          <div class="pagination-display-entries">
            ${perPage * (Number(pageNumber) - 1) + 1}&nbsp;-&nbsp;${perPage * Number(pageNumber)}&nbsp;/&nbsp;${totalCount}
          </div>
        </div>`;
    }
    this.paginationTopTarget.innerHTML = this.paginationTarget.innerHTML
  }

  renderEstate(estate) {
    const walking = estate.main_access_walking_minutes_text;
    let features = '';

    if (estate.card_features && estate.card_features.length > 0) {
      estate.card_features.forEach(feature => {
        features += '<span>' + feature + '</span>' + ' ';
      });
    }

    const div = document.createElement('li');
    div.classList.add('card-estates-item');
    div.dataset.target = 'estate-search.estate';
    div.dataset.action = 'mouseover->estate-search#openInfoWindowTrigger mouseout->estate-search#closeInfoWindowTrigger';
    div.dataset.id = estate.id;
    div.dataset.lat = estate.lat;
    div.dataset.lng = estate.lng;
    const ageAndReformOrRenovation = estate.age_and_reform_or_renovation.length > 0 ? `<p><span class="recently__cate">${estate.age_and_reform_or_renovation}</span></p>` : ``;
    if (this.isArchive == 'true') {
      div.innerHTML = `
        <div class="recently__item ${ ($(window).width() < 767) ? 'sp_list' : ''}">
          <a href="/estates/${estate.id}" target="_blank" rel="noopener noreferrer">
            <div class="recently__item__flex hover">
              <div class="recently__img">
                  <img class="lazyload w-100 map_image" data-src="${estate.image}" src="${estate.image}">
                  <noscript>
                    <img class="lazyload w-100 map_image" data-src="${estate.image}" src="${estate.image}" />
                  </noscript>
              </div>
              <div class="recently__search__body archive">
                ${ageAndReformOrRenovation}
                <div class="recently__head">
                  <h3 class="recently__ttl">${estate.name}</h3>
                </div>
                <p class="recently__txt" data-map-show-address="${estate.address}">
                  ${estate.address}
                  <br />
                  <span class="recently__station" data-map-show-station="${estate.station}駅">${estate.station}駅 <span class="recently__wark--only-list-view">${walking}</span></span>
                </p>
              </div>
            </div>
          </a>
        </div>`;
    } else {
      div.innerHTML = `
        <div class="recently__item ${ ($(window).width() < 767) ? 'sp_list' : ''} ${ estate.published_new ? ' post-new' : ''}">
          <a href="/estates/${estate.id}" target="_blank" rel="noopener noreferrer">
            <div class="recently__item__flex hover">
              <div class="recently__img">
                  <div class="feature-labels">
                    ${this.renderFeatureLabels(estate)}
                  </div>
                  <div class="feature-labels-bottom">
                    ${this.renderFeatureLabelsBottom(estate)}
                  </div>
                  ${this.renderR1Icon(estate.r1, estate.r1eco, estate.ecocube_1_star, estate.ecocube_2_star, estate.ecocube_3_star)}
                  <img class="lazyload w-100 map_image" data-src="${estate.image}" src="${estate.image}">
                  <noscript>
                    <img class="lazyload w-100 map_image" data-src="${estate.image}" src="${estate.image}" />
                  </noscript>
                ${this.renderFavorite(estate.id, estate.favorited)}
              </div>
              <div class="recently__search__body">
                ${ageAndReformOrRenovation}
                <div class="recently__head">
                  <h3 class="recently__ttl">${estate.name}</h3>
                  <p class="recently__desc">${estate.roomplan}/${estate.footprint}㎡</p>
                </div>
                <p class="recently__txt" data-map-show-address="${estate.address}">
                  ${estate.address}
                  <br />
                  <span class="recently__station" data-map-show-station="${estate.station}駅">${estate.station}駅 <span class="recently__wark--only-list-view">${walking}</span></span>
                </p>
                <div class="recently__item__price">
                  <div class="recently__status">
                    <p class="recently__price"><span class="number">${estate.price}</span>万円</p>
                    <p class="price-status" ${estate.recent_price_updated ? '' : 'style="display: none;"'}><span><img src="${this.iconDown}" alt="PRICE DOWN"></span>PRICE DOWN</p>
                  </div>
                  <p class="recently__brokerage">${estate.is_fees_free_display_possible ? estate.brokerage : ''}</p>
                </div>
                <p class="recently__fee">${estate.fee}</p>
                <p class="recently__features--only-gird-view">${features}</p>
              </div>
            </div>
          </a>
          <p class="recently__features--only-list-view sp-none">${features}</p>
        </div>`;
    }

    this.listTarget.appendChild(div);
  }

  renderFavorite(estateId, favorited) {
    if (this.data.get('userSignedIn') !== 'true')
      return `
        <span class="review-icon"
          data-action="click->favorite#redirectToSingIn"
          data-controller="favorite"
          data-favorite-star-image="${this.starImage}"
          data-favorite-star-white-image="${this.starWhiteImage}">
          <img alt="star" data-target="favorite.favoriteImage" src="${this.starImage}">
        </span>`;

    return `
      <span class="review-icon${favorited ? ' active' : ''}"
        data-action="click->favorite#post"
        data-controller="favorite"
        data-favorite-estate-id="${estateId}"
        data-favorite-favorited="${favorited ? 'true' : 'false'}"
        data-favorite-star-image="${this.starImage}"
        data-favorite-star-white-image="${this.starWhiteImage}">
        <img alt="star" data-target="favorite.favoriteImage" src="${this.starImage}">
      </span>`;
  }

  renderR1Icon(estateR1, estateR1eco, estateEcocube1Star, estateEcocube2Star, estateEcocube3Star) {
    if (estateEcocube3Star !== '') {
      return `
        <p class="has-r1 has-r1eco has-ecocube-star">
          <span><img src="${this.iconEcocube3starImage}"></span>
        </p>`;
    } else if (estateEcocube2Star !== '') {
      return `
        <p class="has-r1 has-r1eco has-ecocube-star">
          <span><img src="${this.iconEcocube2starImage}"></span>
        </p>`;
    } else if (estateEcocube1Star !== '') {
      return `
        <p class="has-r1 has-r1eco has-ecocube-star">
          <span><img src="${this.iconEcocube1starImage}"></span>
        </p>`;
    } else if (estateR1eco !== '') {
      return `
        <p class="has-r1 has-r1eco">
          <span><img src="${this.iconR1ecoImage}"></span>
        </p>`;
    } else if (estateR1 !== '') {
      return `
        <p class="has-r1">
          <span><img src="${this.iconR1Image}"></span>
        </p>`;
    } else {
      return '';
    }
  }

  renderFeatureLabels(estate) {
    return `
      <span class="recommend ${estate.smaview_available !== '' ? '' : 'pc-none sp-none'}">Smaview対応</span>
      <span class="recommend individua ${estate.is_individual_seller !== '' ? '' : 'pc-none sp-none'}">売却依頼物件</span>
    `
  }

  renderFeatureLabelsBottom(estate) {
    if (estate.tax_reduction !== '') {
      return `<span class="recommend tax-reduction">${estate.tax_reduction}</span>`;
    }
    return '';
  }

  removeMarkers() {
    this.infoWindow.close();
    this.markers.forEach(marker => marker.setMap(null));
    this.markers = [];
    this.listTarget.innerHTML = '';
    this.paginationTarget.innerHTML = '';
  }

  showAlert() {
    alert('エラーが発生しました。ブラウザをリロードして、再度お試しください。');
  }

  estatesUrl() {
    let url = '/estates?';
    const rooms = this.roomTargets.filter(r => r.checked).map(r => r.value);
    const features = this.featureTargets.filter(f => f.checked).map(f => f.value);
    const currentStates = this.currentStateTargets.filter(f => f.checked).map(f => f.value);
    const walkingMinute = this.walkingMinuteTarget.value;
    const params = {
      rooms: rooms,
      features: features,
      current_states: currentStates,
      walking_minute: walkingMinute,
      zoom: this.zoomTarget.value,
      order: this.orderTarget.value,
      min_price: this.minPriceTarget.value,
      max_price: this.maxPriceTarget.value,
      min_footprint: this.minFootprintTarget.value,
      max_footprint: this.maxFootprintTarget.value,
      per: this.perPageTarget.value
    };

    if (this.perPageTarget.baseURI.indexOf("page") != -1){
      let page = this.perPageTarget.baseURI.split('&').length - 1
      params.page = this.perPageTarget.baseURI.split('&')[page].replace("page=", "")
    }

    if (!this.latTarget.disabled && !this.lngTarget.disabled) {
      params.lat = this.latTarget.value;
      params.lng = this.lngTarget.value;
    }

    if (this.areaId) {
      params.area_id = this.areaId
    }

    if (this.isAreaSearch) {
      if (this.cityTargets.filter(f => f.checked).map(elem => elem.value).length) {
        params.city_ids = this.cityTargets.filter(f => f.checked).map(elem => elem.value);
        params.prefecture_id = this.prefectureTarget.value;
      }
    } else if (this.isStationSearch) {
      // params.prefecture_id = this.prefectureTarget.value;
      params.line_name = this.lineTarget.value;
      params.station_name = this.stationTarget.value;
    } else if (this.isAreaClassificationSearch) {
      if (this.cityTargets.filter(f => f.checked).map(elem => elem.value).length) {
        params.city_ids = this.cityTargets.filter(f => f.checked).map(elem => elem.value);
        params.prefecture_ids = this.prefectureTargets.filter(f => f.checked).map(elem => elem.value)
        params.area_id = this.areaTarget.id
      }
    } else {
      params.keyword = this.keywordTarget.value;
    }

    if (this.hashTag) {
      params.hash_tag = this.hashTag
      this.checkToFeatureCheckBox(this.hashTag)
      this.hashTagConditionChanged(this.hashTag)
      const features = this.featureTargets.filter(f => f.checked).map(f => f.value);
      params.features = features;
    }

    if (this.hashTags) {
      params.hash_tags = this.hashTags
      this.hashTags.forEach(_hashTag => {
        this.checkToFeatureCheckBox(_hashTag)
        this.hashTagConditionChanged(_hashTag)
      })
      const features = this.featureTargets.filter(f => f.checked).map(f => f.value);
      params.features = features;
    }

    if (this.SouthWestLat != null && this.SouthWestLng != null && this.NorthEastLat != null && this.NorthEastLng != null) {
      params.south_west_lat = this.SouthWestLat,
      params.south_west_lng = this.SouthWestLng,
      params.north_east_lat = this.NorthEastLat,
      params.north_east_lng = this.NorthEastLng
    }

    if (this.isArchive == 'true') {
      params.is_archive = this.isArchive
    }

    Object.keys(params).forEach(key => {
      const value = params[key];

      if (value === '')
        return;

      if (Array.isArray(value)) {
        value.forEach(v => url += `${key}[]=${v}&`);
      } else {
        url += `${key}=${value}&`;
      }
    });

    return url;
  }

  archiveEstatesUrl() {
    let url = '/estates/archives?'
    const params = {
      keyword: this.keywordTarget.value
    }

    Object.keys(params).forEach(key => {
      const value = params[key]

      if (value === '')
        return

      if (Array.isArray(value)) {
        value.forEach(v => url += `${key}[]=${v}&`);
      } else {
        url += `${key}=${value}&`
      }
    })

    return url
  }

  checkToFeatureCheckBox(hashTag) {
    switch(hashTag){
      case 'allow_pet':
        const allowPetElem = document.getElementById('features_allow_pet')
        if (!allowPetElem.checked) {
          allowPetElem.click()
        }
        allowPetElem.disabled = true
        break
      case 'auto_lock':
        const autoLockElem = document.querySelector('[data-id=auto_lock]')
        if (!autoLockElem.checked) {
          autoLockElem.click()
        }
        autoLockElem.disabled = true
        break
      case 'r1':
        const r1Elem = document.querySelector('[data-id=r1]')
        if (!r1Elem.checked) {
          r1Elem.click()
        }
        r1Elem.disabled = true
        break
      case 'renovation':
        const renovatedElem = document.getElementById('features_renovation')
        if (!renovatedElem.checked) {
          renovatedElem.click()
        }
        renovatedElem.disabled = true
        break
      case 'invest':
        const currentStatesRentElem = document.getElementById('current_states_rent')
        if (!currentStatesRentElem.checked) {
          currentStatesRentElem.click()
        }
        currentStatesRentElem.disabled = true
        const currentStatesExceptRentElem = document.getElementById('current_states_except_rent')
        currentStatesExceptRentElem.disabled = true
        break
      case 'smaview':
        const smaviewAvailableElem = document.getElementById('features_smaview_available')
        if (!smaviewAvailableElem.checked) {
          smaviewAvailableElem.click()
        }
        smaviewAvailableElem.disabled = true
        break
      case 'energy_saving':
        const energySavingElem = document.querySelector('[data-id=energy_saving]')
        if (!energySavingElem.checked) {
          energySavingElem.click()
        }
        energySavingElem.disabled = true
        break
    }
  }

  get iconDown() {
    return this.data.get('icon-down');
  }

  get starImage() {
    return this.data.get('star-image');
  }

  get starWhiteImage() {
    return this.data.get('star-white-image');
  }

  get estatesApiUrl() {
    return `/api${this.estatesUrl()}`;
  }

  get isSmartphone() {
    return this.data.get('isSmartphone') === 'true';
  }

  get isAreaSearch() {
    return this.data.get('isAreaSearch') === 'true';
  }

  get isAreaClassificationSearch() {
    return this.data.get('isAreaClassificationSearch') === 'true';
  }

  get isStationSearch() {
    return this.data.get('isStationSearch') === 'true';
  }

  get iconR1Image() {
    return this.data.get('icon-r1-image');
  }

  get iconR1ecoImage() {
    return this.data.get('icon-r1eco-image');
  }

  get iconEcocube1starImage() {
    return this.data.get('icon-ecocube1star-image');
  }

  get iconEcocube2starImage() {
    return this.data.get('icon-ecocube2star-image');
  }

  get iconEcocube3starImage() {
    return this.data.get('icon-ecocube3star-image');
  }

  get hashTag() {
    return this.data.get('hash-tag')
  }

  get hashTags() {
    return JSON.parse(this.data.get('hash-tags'))
  }

  get displayKeyword() {
    return this.data.get('display-keyword')
  }

  get isArchive () {
    return this.data.get('is-archive')
  }

  get areaId () {
    return this.data.get('area-id')
  }
}
